import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/images/copy-icon.svg'


const _withScopeId = n => (_pushScopeId("data-v-c1edb08a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "local-registration-container" }
const _hoisted_2 = { class: "nav" }
const _hoisted_3 = { class: "search-panel" }
const _hoisted_4 = { class: "condition-item" }
const _hoisted_5 = { class: "condition-item" }
const _hoisted_6 = { class: "condition-item" }
const _hoisted_7 = { class: "d-f" }
const _hoisted_8 = { class: "condition-item" }
const _hoisted_9 = { class: "d-f" }
const _hoisted_10 = { class: "condition-item" }
const _hoisted_11 = { class: "condition-item" }
const _hoisted_12 = { class: "condition-item" }
const _hoisted_13 = { class: "condition-item" }
const _hoisted_14 = { class: "condition-item" }
const _hoisted_15 = { class: "btn-wrapper" }
const _hoisted_16 = { class: "content-container" }
const _hoisted_17 = { class: "pagination-container d-f justify-e" }
const _hoisted_18 = { class: "batch-query-title" }
const _hoisted_19 = { style: {"padding":"0 8px"} }
const _hoisted_20 = { class: "list-title" }
const _hoisted_21 = { class: "list-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Breadcrumb = _resolveComponent("Breadcrumb")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_brand_selector = _resolveComponent("brand-selector")!
  const _component_a_range_picker = _resolveComponent("a-range-picker")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_pagination = _resolveComponent("a-pagination")!
  const _component_a_modal = _resolveComponent("a-modal")!
  const _component_program_code_mapping = _resolveComponent("program-code-mapping")!
  const _component_start_system_claim = _resolveComponent("start-system-claim")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_Breadcrumb, {
          class: "title-bg",
          paddingBottom: "28",
          dataSource: []
        }),
        _createElementVNode("div", null, [
          (_ctx.$btnPermissions('SSSCO-01'))
            ? (_openBlock(), _createBlock(_component_a_button, {
                key: 0,
                type: "primary",
                class: "btn",
                onClick: _ctx.handleShowProgramCodeMappingDrawer
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('ssscOrder.programCodeMapping')), 1)
                ]),
                _: 1
              }, 8, ["onClick"]))
            : _createCommentVNode("", true),
          (_ctx.$btnPermissions('SSSCO-02'))
            ? (_openBlock(), _createBlock(_component_a_button, {
                key: 1,
                type: "primary",
                class: "btn",
                onClick: _ctx.handleShowStartSystemClaimDrawer
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('ssscOrder.startSystemClaim')), 1)
                ]),
                _: 1
              }, 8, ["onClick"]))
            : _createCommentVNode("", true),
          (_ctx.$btnPermissions('SSSCO-03'))
            ? (_openBlock(), _createBlock(_component_a_button, {
                key: 2,
                class: _normalizeClass([_ctx.closeScroll ? 'btn-export' : 'btn-export-closed']),
                onClick: _ctx.handleExport
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('ssscOrder.export')), 1)
                ]),
                _: 1
              }, 8, ["class", "onClick"]))
            : _createCommentVNode("", true)
        ])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("label", null, _toDisplayString(_ctx.$t('ssscOrder.brand')), 1),
          _createVNode(_component_brand_selector, {
            value: _ctx.conditions.brand,
            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.conditions.brand) = $event)),
            placeholder: _ctx.$t('ssscOrder.pleaseSelect'),
            allowClear: ""
          }, null, 8, ["value", "placeholder"])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("label", null, _toDisplayString(_ctx.$t('ssscOrder.updatedTime')), 1),
          _createVNode(_component_a_range_picker, {
            value: _ctx.conditions.updatedTime,
            "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.conditions.updatedTime) = $event)),
            placeholder: [_ctx.$t('ssscOrder.beginTime'), _ctx.$t('ssscOrder.endTime')]
          }, null, 8, ["value", "placeholder"])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("label", null, _toDisplayString(_ctx.$t('ssscOrder.fin')), 1),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_a_input, {
              value: _ctx.conditions.finNo,
              "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.conditions.finNo) = $event)),
              placeholder: _ctx.$t('ssscOrder.pleaseInput')
            }, null, 8, ["value", "placeholder"]),
            _createElementVNode("img", {
              src: _imports_0,
              style: {"margin":"0 0 0 8px","cursor":"pointer"},
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.batchQueryEvent(_ctx.BatchQueryType.FIN)))
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("label", null, _toDisplayString(_ctx.$t('ssscOrder.vin')), 1),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_a_input, {
              value: _ctx.conditions.vinNo,
              "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.conditions.vinNo) = $event)),
              placeholder: _ctx.$t('ssscOrder.pleaseInput')
            }, null, 8, ["value", "placeholder"]),
            _createElementVNode("img", {
              src: _imports_0,
              style: {"margin":"0 0 0 8px","cursor":"pointer"},
              onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.batchQueryEvent(_ctx.BatchQueryType.VIN)))
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("label", null, _toDisplayString(_ctx.$t('ssscOrder.programCode')), 1),
          _createVNode(_component_a_input, {
            value: _ctx.conditions.programCode,
            "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.conditions.programCode) = $event)),
            placeholder: _ctx.$t('ssscOrder.pleaseInput')
          }, null, 8, ["value", "placeholder"])
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("label", null, _toDisplayString(_ctx.$t('ssscOrder.packageValidFrom')), 1),
          _createVNode(_component_a_range_picker, {
            value: _ctx.conditions.packageValidFrom,
            "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.conditions.packageValidFrom) = $event)),
            placeholder: [_ctx.$t('ssscOrder.beginTime'), _ctx.$t('ssscOrder.endTime')]
          }, null, 8, ["value", "placeholder"])
        ]),
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("label", null, _toDisplayString(_ctx.$t('ssscOrder.typeClass')), 1),
          _createVNode(_component_a_input, {
            value: _ctx.conditions.typeClass,
            "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.conditions.typeClass) = $event)),
            placeholder: _ctx.$t('ssscOrder.pleaseInput')
          }, null, 8, ["value", "placeholder"])
        ]),
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("label", null, _toDisplayString(_ctx.$t('ssscOrder.status')), 1),
          _createVNode(_component_a_select, {
            value: _ctx.conditions.status,
            "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.conditions.status) = $event)),
            placeholder: _ctx.$t('ssscOrder.pleaseSelect'),
            allowClear: ""
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.statusList, (status) => {
                return (_openBlock(), _createBlock(_component_a_select_option, {
                  value: status,
                  key: status
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(status), 1)
                  ]),
                  _: 2
                }, 1032, ["value"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["value", "placeholder"])
        ]),
        _createElementVNode("div", _hoisted_14, [
          _createElementVNode("label", null, _toDisplayString(_ctx.$t('ssscOrder.description')), 1),
          _createVNode(_component_a_input, {
            value: _ctx.conditions.description,
            "onUpdate:value": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.conditions.description) = $event)),
            placeholder: _ctx.$t('ssscOrder.pleaseInput')
          }, null, 8, ["value", "placeholder"])
        ]),
        _createElementVNode("div", _hoisted_15, [
          (_ctx.$btnPermissions('SSSCO-04'))
            ? (_openBlock(), _createBlock(_component_a_button, {
                key: 0,
                onClick: _ctx.handleReset
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('ssscOrder.reset')), 1)
                ]),
                _: 1
              }, 8, ["onClick"]))
            : _createCommentVNode("", true),
          (_ctx.$btnPermissions('SSSCO-05'))
            ? (_openBlock(), _createBlock(_component_a_button, {
                key: 1,
                type: "primary",
                onClick: _ctx.handleSearch
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('ssscOrder.search')), 1)
                ]),
                _: 1
              }, 8, ["onClick"]))
            : _createCommentVNode("", true)
        ])
      ]),
      _createElementVNode("div", _hoisted_16, [
        _createVNode(_component_a_table, {
          class: "data-table",
          columns: _ctx.columns,
          "data-source": _ctx.data,
          pagination: false,
          rowKey: "otrId",
          scroll: { x: true, y: _ctx.tableSize.height - 40 }
        }, null, 8, ["columns", "data-source", "scroll"]),
        _createElementVNode("div", _hoisted_17, [
          _createVNode(_component_a_pagination, {
            total: _ctx.pagination.total,
            "show-size-changer": "",
            "show-total": (total) => (_ctx.locale === 'en' ? `Total ${total} items` : `共 ${total} 条`),
            pageSize: _ctx.pagination.pageSize,
            "onUpdate:pageSize": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.pagination.pageSize) = $event)),
            current: _ctx.pagination.current,
            "onUpdate:current": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.pagination.current) = $event)),
            pageSizeOptions: ['10', '20', '30', '40', '50'],
            onChange: _ctx.handleQueryData,
            onShowSizeChange: _ctx.handleQueryData
          }, {
            buildOptionText: _withCtx((props) => [
              _createElementVNode("span", null, _toDisplayString(`${props.value}${_ctx.locale === 'en' ? ' / page' : '条/页'}`), 1)
            ]),
            _: 1
          }, 8, ["total", "show-total", "pageSize", "current", "onChange", "onShowSizeChange"])
        ])
      ])
    ]),
    _createVNode(_component_a_modal, {
      visible: _ctx.batchQueryModal,
      "onUpdate:visible": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.batchQueryModal) = $event)),
      width: 420,
      onCancel: _ctx.closeBatchQueryModal,
      class: "batch-query-modal",
      centered: ""
    }, {
      title: _withCtx(() => [
        _createElementVNode("strong", _hoisted_18, _toDisplayString(_ctx.batchQueryTitle), 1)
      ]),
      footer: _withCtx(() => [
        _createVNode(_component_a_button, {
          size: "small",
          onClick: _ctx.clearBatchQuery
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('claimEnquiry.cancel')), 1)
          ]),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_a_button, {
          size: "small",
          type: "primary",
          onClick: _cache[14] || (_cache[14] = ($event: any) => {
          _ctx.batchQueryModal = false;
          _ctx.closeBatchQueryModal();
        })
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('claimEnquiry.ok')), 1)
          ]),
          _: 1
        })
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_19, [
          _createElementVNode("article", null, [
            _createElementVNode("section", _hoisted_20, _toDisplayString(_ctx.batchQueryListTitle), 1),
            _createElementVNode("section", _hoisted_21, [
              (_ctx.textNoArray.length > 0)
                ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.textNoArray, (item, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: "list-item",
                      key: index
                    }, _toDisplayString(item), 1))
                  }), 128))
                : _createCommentVNode("", true),
              _createVNode(_component_a_input, {
                ref: "input",
                value: _ctx.textContentNo,
                "onUpdate:value": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.textContentNo) = $event)),
                onChange: _ctx.textChangEvent,
                style: {"height":"24px"}
              }, null, 8, ["value", "onChange"]),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.textNoArray.length > 0 ? 0 : 6, (index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: index,
                  class: "list-item",
                  style: {"height":"24px","margin-top":"8px"}
                }))
              }), 128))
            ])
          ])
        ])
      ]),
      _: 1
    }, 8, ["visible", "onCancel"]),
    _createVNode(_component_program_code_mapping, {
      visible: _ctx.programCodeMappingVisible,
      "onUpdate:visible": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.programCodeMappingVisible) = $event)),
      onConfirm: _ctx.handleSearch
    }, null, 8, ["visible", "onConfirm"]),
    _createVNode(_component_start_system_claim, {
      visible: _ctx.startSystemClaimVisible,
      "onUpdate:visible": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.startSystemClaimVisible) = $event)),
      onConfirm: _ctx.handleSearch
    }, null, 8, ["visible", "onConfirm"])
  ], 64))
}